import { Component, OnDestroy } from '@angular/core';
import { GetTaxiService } from '../../services/get-taxi.service';
import { Subject, Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ParameterService } from 'src/app/services/parameter.service';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentMonthName } from 'src/tools/get-current-month-name';
import { DisplayOptions } from 'src/app/models/enums';

@Component({
    selector: 'app-coin-tree-counter-container',
    template: `
        <app-coin-tree-counter
                (onLoadEmitter)="onLoad()"
                [completedTaxiCountTotal]="completedTaxiCountTotal"
                [completedTaxiCountMonth]="completedTaxiCountMonthly"
                [monthlyRidesGoal]="monthlyRidesGoal"
                [displayCoinCounter]="displayCoinCounter"
                [displayBasicCounter]="displayBasicCounter"
                [displayTreeCounter]="displayTreeCounter"
                [displayCoinProgressBar]="displayCoinProgressBar"
                [currency]="currency"
                [earnedCoinsPerRide]="earnedCoinsPerRide"
                [alreadyGotPaidAmount]="alreadyGotPaidAmount"
                [earnedCoinsDescription]="earnedCoinsDescription"
                [prepaidTourCompletedDescription]="prepaidTourCompletedDescription"
                [prepaidTourCompleted]="prepaidTourCompleted"
                [monthlyBonusGoalTitle]="getMonthlyBonusGoalTitle()"
                [displayPopupOnPrepaidCompleted]="displayPopupOnPrepaidCompleted">
        </app-coin-tree-counter>`,
})
export class CoinCounterContainerComponent implements OnDestroy {
    hotelId: string = "";
    accessToken: any;
    completedTaxiCountTotal: number = 0;
    completedTaxiCountMonthly: number = 0;
    monthlyRidesGoal: number = 0;
    displayTreeCounter: boolean = false;
    displayCoinCounter: boolean = false;
    displayBasicCounter: boolean = false;
    displayCoinProgressBar: boolean = false;
    displayPopupOnPrepaidCompleted: boolean = false;
    currency: string = "";
    earnedCoinsPerRide: number = 0;
    alreadyGotPaidAmount: number = 0;
    earnedCoinsDescription: string = "";
    prepaidTourCompletedDescription: string = "";
    prepaidTourCompleted: boolean = false;
    subscr$ = new Subject();

    timerSubscription?: Subscription;

    constructor(public service: GetTaxiService, private parameterService: ParameterService, private translate: TranslateService) {
        this.service.hotelInfo$.pipe(takeUntil(this.subscr$)).subscribe((data) => {
            this.displayTreeCounter = (data.displayOptions & DisplayOptions.DisplayTreeCounter) === DisplayOptions.DisplayTreeCounter;
            this.displayCoinCounter = (data.displayOptions & DisplayOptions.DisplayCoinCounter) === DisplayOptions.DisplayCoinCounter;
            this.displayBasicCounter = (data.displayOptions & DisplayOptions.DisplayBasicCounter) === DisplayOptions.DisplayBasicCounter;
            this.displayCoinProgressBar = (data.displayOptions & DisplayOptions.DisplayCoinProgressBar) === DisplayOptions.DisplayCoinProgressBar;
            this.monthlyRidesGoal = data.monthlyRidesGoal;
            this.currency = data.currency;
            this.earnedCoinsPerRide = data.earnedCoinsPerRide;
            this.alreadyGotPaidAmount = data.alreadyGotPaidAmount;
            this.earnedCoinsDescription = data.earnedCoinsDescription;
            this.displayPopupOnPrepaidCompleted = (data.displayOptions & DisplayOptions.DisplayPopupOnPrepaidCompleted) === DisplayOptions.DisplayPopupOnPrepaidCompleted;;
            this.prepaidTourCompletedDescription = this.translate.instant('prepaid-tour-completed-description')
                .replace('{{coinsearnedperride}}', this.earnedCoinsPerRide)
                .replace('{{currency}}', this.currency);
        });

        this.parameterService.parameters.subscribe(async (params) => {
            if (params === undefined)
                return;

            this.hotelId = params.hotelId;
            this.accessToken = params.accessToken;
        });
    }

    public onLoad(): void {
        const body = {
            hotelId: this.hotelId,
            accessToken: this.accessToken,
        };
        this.timerSubscription = timer(0, 120000).pipe(
            map(() => {
                this.service.getCompletedTaxiCount(body).subscribe(async (data) => {
                    if (this.completedTaxiCountTotal != 0 && this.completedTaxiCountTotal < data)
                        this.prepaidTourCompleted = true;
                    else
                        this.prepaidTourCompleted = false;

                    this.completedTaxiCountTotal = data.completedRidesTotal;
                    this.completedTaxiCountMonthly = data.completedRidesMonthly;
                });
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.timerSubscription?.unsubscribe();
        this.subscr$.next();
        this.subscr$.complete();
    }

    getMonthlyBonusGoalTitle(): string {
        return this.translate.instant('monthly_bonus_goal_title').replace('{month}', getCurrentMonthName());
    }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Type, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { ValidationTools } from 'src/tools/ValidationTools';
import { LocalStorageTools } from 'src/tools/LocalStorageTools';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { ReceiptService } from 'src/app/services/receipt.service';
import { HotelService, HotelSystemIntegration, NoDestinationSettings, PaymentOption } from 'src/app/services/hotel.service';
import { Subject, Subscription, interval } from 'rxjs';
import { GooglePlaceDirective } from 'src/app/directives/google-place/google-place.directive';
import { MatDialog } from '@angular/material/dialog';
import { LargeTaxiPopupComponent } from '../large-taxi-popup/large-taxi-popup.component';
import { PaymentApiService } from 'src/app/services/api/payment-api.service';
import { ErrorOrderPopupComponent } from 'src/app/shared/components/error-order-popup/error-order-popup.component';
import { PaidPopupComponent } from 'src/app/shared/components/paid-popup/paid-popup.component';
import { CollectPaymentPopupComponent } from 'src/app/shared/components/collect-payment-popup/collect-payment-popup.component';
import { ParameterService } from 'src/app/services/parameter.service';
import { CollectPaymentMethodResponse } from 'src/app/models/payment/terminal.interface';
import { IPaymentIntentResponse } from 'src/app/models/payment-intent-response.interface';
import { OrderType } from 'src/app/enums/order-type.enum';
import { IDestination } from 'src/app/shared/models/destination.interface';
import { PayInTaxiPopupComponent } from 'src/app/shared/components/pay-in-taxi-popup/pay-in-taxi-popup.component';
import { environment } from 'src/environments/environment';
import { HotelSettings, RideType } from 'src/app/models/enums';
import { AttributesToTaxi, RideService } from 'src/app/services/ride.service';
import { DriverComments } from 'src/app/services/models/driverComments.model';
import { LogService } from 'src/app/services/log.service';
import { NewPayInTaxiPopupComponent } from 'src/app/shared/components/new-pay-in-taxi-popup/new-pay-in-taxi-popup.component';
import { TaxiPopupComponentType } from 'src/app/models/taxipopup-component.interface';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { ICreatePaymentIntentRequest } from 'src/app/models/payment/createIntentRequest.interface';
import { ISaveOtherDestinationTerminalRequest } from 'src/app/models/ride/save-otherdestination-terminal.interface';
import { HotelResponseMapperService } from 'src/app/mappers/hotel-response-mapper.service';
import { GetHotelSystem } from 'src/app/services/get-hotel.service';
import { HotelSystemCustomer } from 'src/app/models/hotel-system-intergration/hotel-system-customer.interface';
import { RoomNumberIncorrect } from 'src/app/shared/components/room-number-incorrect-popup/room-number-incorrect-popup.component';
import { IAddress } from 'src/app/models/address.interface';
import { ChooseWhichCommentComponent } from 'src/app/shared/components/choose-which-comment/choose-which-comment.component';
import { SpecialHotelInformationPopup } from 'src/app/shared/components/special-hotel-info-popup/special-hotel-info-popup.component';
import { ISaveOtherDestinationRequest } from 'src/app/models/ride/save-otherdestination.interface';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';

@Component({
  selector: 'app-second-destination',
  templateUrl: './second-destination.component.html',
  styleUrls: ['./second-destination.component.scss']
})
export class SecondDestinationComponent implements OnInit, OnDestroy {

  availableOptions: string[] = [];
  @Input() isManual = false;

  step = 5 / 60;
  loading!: HTMLIonLoadingElement;

  defaultCommentToTaxiDriverSubscription?: Subscription;
  paymentOptionsSubscription?: Subscription;
  hotelInfo?: IHotelInfo;
  subscr$ = new Subject();

  updateRoomNumberAndGuestNameRequirements() {
    if ((this.hasDestination() && (this.paymentOption!.value & PaymentOption.ApplyToBill) == PaymentOption.ApplyToBill) || (this.paymentOption!.value & PaymentOption.PayByTerminal) == PaymentOption.PayByTerminal) {
      if (this.roomNo && this.guestName) {
        this.roomNo?.setValidators([this.tools.oneOfTwoFieldsRequired(this.guestName)]);
        this.guestName?.setValidators([this.tools.oneOfTwoFieldsRequired(this.roomNo)])
      }
      if ((this.paymentOption!.value & PaymentOption.ApplyToBill) == PaymentOption.ApplyToBill) {
        this.roomNo?.setValidators(Validators.required)
      }
    }
    else {
      this.roomNo?.clearValidators();
      this.guestName?.clearValidators();
    }

    if (this.destination!.value.address == "NoDestination") {
      this.roomNo?.clearValidators();
      this.guestName?.clearValidators();
    }

    if (this.destination!.value.address == "FromHotelAddress") {
      this.phoneNo?.setValidators(Validators.required)
      this.guestName?.setValidators(Validators.required)
    }

    this.roomNo!.updateValueAndValidity();
    this.guestName!.updateValueAndValidity();
  }

  showOnLarge: boolean = false;
  isShowShare($event: any, largeTaxi?: boolean) {

    if ($event !== null && $event.checked && largeTaxi) {
      this.openPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
    }
    this.showOnLarge = this.secondDestination.value.largeTaxi;

    this.priceService.setIsLargeTaxi(this.secondDestination.value.largeTaxi);

    this.updateRoomNumberAndGuestNameRequirements();

    if (this.secondDestination.value.largeTaxi)
      this.stationCar?.disable();
    else
      this.stationCar?.enable();

    if (this.secondDestination.value.stationCar)
      this.largeTaxi?.disable();
    else
      this.largeTaxi?.enable();
  }

  public secondDestination: FormGroup;
  hotelId: any;
  airportId: any;
  terminalReaderId: any;
  accessToken: any;
  otherDestinations: (IDestination & { detailedAddress?: IAddress })[] = [];
  messageShow: boolean = false;
  showEmote: boolean = false;
  showLargeTaxiLoading: boolean = false;
  canOrderLargeTaxi = true;
  driverComments?: DriverComments;
  hotelSystemIntegrationApplyToBill = false;
  hideNameAndPhone = false;
  showApplyToBill = false;
  noDestinationSelected = false;
  getCustomer = false;
  progressValue: number = 0;
  timeOutTimer: number = 5000;
  largeTaxiCarResponse: string = "";
  loadingDone: boolean = false;
  taxiBookingId: string = "";
  paymentOptionPayByTerminal: boolean = false;
  toHotelActive: boolean = false;
  phoneNumber: any = { phoneNumber: "", countryCode: "" };
  boldCount: number = 0;

  isLogoAvailable: { [key: string]: boolean } = {};
  taxiNames: string[] = [];
  selectedTaxiCompany: string | undefined;

  numberOfCarsSelectionOptions: number[] = [1, 2, 3, 4];

  constructor(private tools: ValidationTools, private fb: FormBuilder, public dialog: MatDialog, private alertController: AlertController,
    private paymentApiService: PaymentApiService, public translate: TranslateService, private getTaxiService: GetTaxiService, private saveTaxiService: SaveTaxiService,
    private getHotelSystem: GetHotelSystem, public loadingController: LoadingController, private receiptService: ReceiptService, private hotelService: HotelService,
    private parameterService: ParameterService, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, private log: LogService,
    private rideService: RideService, public receptionistInitialsService: ReceptionistInitialsService, private readonly cdr: ChangeDetectorRef) {

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
      this.terminalReaderId = params.terminalReaderId;

      this.getTaxiService.getHotelInfo(params.hotelId)
        .subscribe(data => {
          this.hotelInfo = HotelResponseMapperService.mapHotelInfoToIHotelInfo(data)

          var getOtherAmount = 5;

          if (!this.terminalReaderId)
            this.terminalReaderId = this.hotelInfo.terminalReaderId;

          this.initTerminal();

          if ((this.hotelInfo.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
            this.hideNameAndPhone = true;

          if ((this.hotelInfo.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
            this.hotelSystemIntegrationApplyToBill = true;

          if ((this.hotelInfo.integrationOptions & HotelSystemIntegration.GetCustomer) === HotelSystemIntegration.GetCustomer)
            this.getCustomer = true;

          if (this.hotelInfo.noDestinationSettings == NoDestinationSettings.AllowedNoOrderNowButton) {
            this.otherDestinations.push({ name: this.translate.instant('NoDestination'), address: "NoDestination" });
            getOtherAmount += this.checkIfNoToHotelButton();
            getOtherAmount = getOtherAmount + 1;
          }

          else if (this.hotelInfo.noDestinationSettings == NoDestinationSettings.Allowed || this.hotelInfo.noDestinationSettings == NoDestinationSettings.AllowedWithNameAndPhone) {
            this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
            this.otherDestinations.push({ name: this.translate.instant('NoDestination'), address: "NoDestination" });
            getOtherAmount += this.checkIfNoToHotelButton();
          }

          else if (this.hotelInfo.noDestinationSettings == NoDestinationSettings.OnlyOrderNowButton) {
            this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
            getOtherAmount += this.checkIfNoToHotelButton();
            getOtherAmount = getOtherAmount + 1;
          }

          else {
            getOtherAmount = getOtherAmount + 3;
          }

          this.boldCount = 8 - getOtherAmount;

          let body = {
            hotelId: this.hotelId,
            accessToken: this.accessToken,
            amount: getOtherAmount
          }

          this.getTaxiService.getOtherDestinations(body).subscribe((data) => {
            this.otherDestinations = this.otherDestinations.concat(data);
          });

          //We are able to access the paymentOptionsValue becuase the hotelService.paymentOptionsValue has been initialized at this point.
          this.updateAfterPaymentOptions();
          this.hotelService.paymentOptions.subscribe(value => {
            this.updateAfterPaymentOptions();
          });
        });
    });

    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.secondDestination = fb.group({
      destination: [null, Validators.required],
      from: [null],
      roomNo: [null],
      commentToDriver: [null],
      guestName: [null],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]],
      pickupTime: [new Date(), Validators.required],
      largeTaxi: [false],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      stationCar: [false],
      animal: [false],
      bike: [false],
      electricCar: [false],
      numberOfGuests: [4],
      paymentOption: [PaymentOption.PayInTaxi, Validators.required],
      taxiPrice: [0],
      carseat: [false],
      oneBoosterSeat: [false],
      twoBoosterSeats: [false],
      childSeat: [false],
      hybridCar: [false],
    });

    this.paymentOption?.valueChanges.subscribe(_ => {
      this.handleDestinationOrPaymentUpdated();
    });

    this.numberOfCars?.valueChanges.subscribe(_ => {
      this.priceService.setNumberOfCars(this.numberOfCars?.value);
    })

    priceService.taxiPrice.subscribe(price => {
      var roomNo = this.roomNo;
      var guestName = this.guestName;
      this.taxiPrice?.setValue(0);
      if (price === undefined)
        roomNo!.setValidators([]);
      else {
        this.taxiPrice?.setValue(price.customerPrice);

        roomNo!.setValidators([tools.oneOfTwoFieldsRequired(guestName!)]);
        guestName?.setValidators([tools.oneOfTwoFieldsRequired(roomNo!)]);
      }
      guestName?.updateValueAndValidity();
      roomNo!.updateValueAndValidity();
    });

    this.commentToDriver!.setValue(hotelService.defaultCommentToTaxiDriverValue);
    this.defaultCommentToTaxiDriverSubscription = hotelService.defaultCommentToTaxiDriver.subscribe(comment => this.commentToDriver!.setValue(comment));
  }

  ngOnInit(): void {
    this.taxiConfigurationService.taxiAvailableOptions.subscribe((data) => {
      this.availableOptions = data;
    });

    this.taxiConfigurationService.taxiNames.subscribe((data) => {
      this.taxiNames = data;
      this.taxiNames.forEach(taxiName => {
        this.isLogoAvailable[taxiName] = true;
      });
    });

    this.taxiConfigurationService.taxiName.subscribe((data) => {
      if (!this.selectedTaxiCompany) {
        this.selectedTaxiCompany = data;
        this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
        this.cdr.detectChanges();
      }
    });

    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.taxiNames = this.taxiConfigurationService.taxiNamesValues;
    this.taxiNames.forEach(taxiName => {
      this.isLogoAvailable[taxiName] = true;
    });

    if (!this.selectedTaxiCompany) {
      this.selectedTaxiCompany = this.taxiConfigurationService.taxiNameValue;
      this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
      this.cdr.detectChanges();
    }

    this.destination?.valueChanges.subscribe(value => {
      if (value?.address && value.address != "FromHotelAddress") {
        this.rideService.getFixedComment(value.address, this.paymentOption?.value).subscribe(
          (data: DriverComments) => {
            this.driverComments = data;
            if (!this.driverComments.prependComment) { this.commentToDriver!.setValue(this.driverComments.defaultComment) }
            else { this.commentToDriver!.setValue(this.driverComments.prependComment + ", " + this.driverComments.defaultComment) }
            if (this.driverComments.chooseACommentList) {
              const chooseWhichComment = this.dialog.open(ChooseWhichCommentComponent, {
                disableClose: true, data: {
                  chooseACommentList: this.driverComments.chooseACommentList
                }
              });

              chooseWhichComment.afterClosed().subscribe((result) => {
                this.commentToDriver!.setValue(result + ", " + this.driverComments!.defaultComment)
              });
            }
          }
        )
      }
      else if (value.address == "FromHotelAddress")
        this.commentToDriver?.setValue(this.translate.instant("toHotelDefaultComment"))
      this.destination?.setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
      this.handleDestinationOrPaymentUpdated();
    }, error => {
      this.log.error("Error on address", {
        errorObject: error,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        location: this.destination
      });
    });

    this.from?.valueChanges.subscribe(value => {
      if (!value?.address) return;
      this.priceService.setFrom(value)
    }, error => {
      this.log.error("Error on address", {
        errorObject: error,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        location: this.destination
      });
    });

    this.priceService.setManualPrice(this.secondDestination.value.taxiPrice);

    this.priceService.setFromHotelAddress();

    this.secondDestination.get('destination')?.valueChanges.subscribe(async (value: IDestination) => {
      if (!value?.address) return;
      if (value?.address == "FromHotelAddress") {
        this.toHotelActive = true;
        this.priceService.setTo({ address: this.hotelInfo!.address, name: this.hotelInfo!.name }, false)
        this.priceService.setFrom(value, false)
        return;
      }
      else {
        this.priceService.setFrom({ address: this.hotelInfo!.address, name: this.hotelInfo!.name }, false)
        this.toHotelActive = false;
      }
      this.priceService.setTo(value);
    }, error => {
      this.log.error("Error on address", {
        errorObject: error,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        location: this.destination
      });
    });

    this.secondDestination.get("largeTaxi")?.valueChanges.subscribe((value: boolean) => {
      this.priceService.setIsLargeTaxi(value);
    });
  }

  ngOnDestroy(): void {
    this.defaultCommentToTaxiDriverSubscription?.unsubscribe();
    this.paymentOptionsSubscription?.unsubscribe();

    this.subscr$.next();
    this.subscr$.complete();
  }

  updateAfterPaymentOptions(): void {
    if ((this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal)
      this.paymentOptionPayByTerminal = true;

    if (((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi) &&
      ((this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) !== PaymentOption.PayByTerminal &&
        !this.hotelInfo!.hasNewOrderingFlow)) {
      if (!this.otherDestinations.some(oD => oD.address === "OrderNow") && this.hotelInfo!.noDestinationSettings != NoDestinationSettings.AllowedNoOrderNowButton)
        this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
      if (!this.otherDestinations.some(oD => oD.address === "FromHotelAddress")
        && this.hotelInfo!.hotelSettings != HotelSettings.RemoveToHotelButton
        && this.hotelInfo!.noDestinationSettings != NoDestinationSettings.OnlyOrderNowButton)
        this.otherDestinations.push({ name: this.translate.instant('orderFromHotel'), address: "FromHotelAddress" });
      if (!this.otherDestinations.some(oD => oD.address === "NoDestination") && this.hotelInfo!.noDestinationSettings != NoDestinationSettings.OnlyOrderNowButton)
        this.otherDestinations.push({ name: this.translate.instant('NoDestination'), address: "NoDestination" });
    }

    if (this.hotelInfo!.noDestinationSettings == NoDestinationSettings.Allowed || this.hotelInfo!.noDestinationSettings == NoDestinationSettings.AllowedWithNameAndPhone || this.hotelInfo!.noDestinationSettings == NoDestinationSettings.AllowedNoOrderNowButton) {
      if (!this.otherDestinations.some(oD => oD.address === "OrderNow") && this.hotelInfo!.noDestinationSettings != NoDestinationSettings.AllowedNoOrderNowButton)
        this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
      if (!this.otherDestinations.some(oD => oD.address === "FromHotelAddress") && this.hotelInfo!.hotelSettings != HotelSettings.RemoveToHotelButton)
        this.otherDestinations.push({ name: this.translate.instant('orderFromHotel'), address: "FromHotelAddress" });
      if (!this.otherDestinations.some(oD => oD.address === "NoDestination"))
        this.otherDestinations.push({ name: this.translate.instant('NoDestination'), address: "NoDestination" });
    }

    if (this.hotelInfo!.noDestinationSettings == NoDestinationSettings.OnlyOrderNowButton) {
      if (!this.otherDestinations.some(oD => oD.address === "OrderNow"))
        this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
    }

    if (!this.otherDestinations.some(oD => oD.address === "FromHotelAddress") && this.hotelInfo!.hotelSettings != HotelSettings.RemoveToHotelButton)
      this.otherDestinations.push({ name: this.translate.instant('orderFromHotel'), address: "FromHotelAddress" });
  }

  checkIfNoToHotelButton(): number {
    if ((this.hotelInfo!.hotelSettings & HotelSettings.RemoveToHotelButton) !== HotelSettings.RemoveToHotelButton) {
      this.otherDestinations.push({ name: this.translate.instant('orderFromHotel'), address: "FromHotelAddress" });
      return 0;
    }
    else
      return 1
  }

  hotelPaymentChanged(hotelPayment: boolean) {
    if (hotelPayment)
      this.paymentOption!.setValue(PaymentOption.HotelPays);
    else
      this.paymentOption!.setValue(PaymentOption.PayInTaxi);
  }

  hasDestination(): boolean {
    return !(this.destination!.value != null && this.destination!.value.address == "NoDestination");
  }

  onTaxiBoxClick(name: string): void {
    this.selectedTaxiCompany = name;
    this.taxiConfigurationService.updateSelectedTaxiCompany(name);
    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;
    this.priceService.setChosenTaxiCompany(name, true);
  }

  hasMultipleTaxiCompanies(): boolean {
    if (this.taxiNames && this.taxiNames.length > 1)
      return true;
    else
      return false;
  }

  get receptionistInitials() {
    return this.secondDestination.get("receptionistInitials");
  }

  get commentToDriver() {
    return this.secondDestination.get('commentToDriver');
  }

  get numberOfCars() {
    return this.secondDestination.get('numberOfCars');
  }

  get roomNo() {
    return this.secondDestination.get('roomNo');
  }

  get animal() {
    return this.secondDestination.get('animal');
  }

  get bike() {
    return this.secondDestination.get('bike');
  }

  get electricCar() {
    return this.secondDestination.get('electricCar');
  }

  get carseat() {
    return this.secondDestination.get('carseat');
  }

  get oneBoosterSeat() {
    return this.secondDestination.get('oneBoosterSeat');
  }

  get twoBoosterSeats() {
    return this.secondDestination.get('twoBoosterSeats');
  }

  get childSeat() {
    return this.secondDestination.get('childSeat');
  }

  get guestName() {
    return this.secondDestination.get("guestName");
  }

  get phoneNo() {
    return this.secondDestination.get('phoneNo');
  }

  get destination() {
    return this.secondDestination.get('destination');
  }

  get from() {
    return this.secondDestination.get('from');
  }

  get pickupTime() {
    return this.secondDestination.get('pickupTime');
  }

  get largeTaxi() {
    return this.secondDestination.get('largeTaxi');
  }

  get numberOfGuests() {
    return this.secondDestination.get('numberOfGuests');
  }

  get stationCar() {
    return this.secondDestination.get('stationCar');
  }

  get hybridCar() {
    return this.secondDestination.get('hybridCar');
  }

  get paymentOption() {
    return this.secondDestination.get('paymentOption');
  }

  get taxiPrice() {
    return this.secondDestination.get('taxiPrice');
  }

  get largeTaxiTranslation() {
    if (this.hotelInfo?.country === 'Norway') {
      const now = new Date("2024-08-25");
      const day = now.getUTCDay(); // 0 (Sunday) to 6 (Saturday)
      const hour = now.getUTCHours(); // Hours in UTC

      // Check if it's between Friday 15:00 and Monday 08:00 UTC
      if (
        (day === 5 && hour >= 15) || // Friday after 15:00
        (day === 6) || // Saturday
        (day === 0) || // Sunday
        (day === 1 && hour < 8) // Monday before 08:00
      ) {
        return this.translate.instant('largeTaxiDescription-norway');
      }
    }
    return this.translate.instant('fixedDestination-largeTaxiDescription');
  }

  paymentIntentId?: string; //This is used to parse through to the goSaveOtherDestinations method to save the other destinations but with the same paymentIntentId as when trying to payByTerminal

  paymentOptionFilter(option: PaymentOption) {
    if ((option & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      return PaymentOption.PayByTerminal
    }
    return option;
  };

  paymentOptionFilterIntegration(option: PaymentOption) {
    if ((option & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill) {
      return PaymentOption.ApplyToBill
    }
    return option;
  };

  async submit() {
    if (this.pickupTime?.value) {
      this.getHotelSystem.getSpecialHotelInformation(this.pickupTime!.value.toISOString())
        .subscribe(
          data => {
            if (data && data.length > 0) {
              const specialInformation = this.dialog.open(SpecialHotelInformationPopup, { data })
              specialInformation.afterClosed().subscribe(_ => this.order())
            }
            else
              this.order();
          },
          error => {
            this.order()
          }
        );
    }
    else
      this.order();
  }

  formSubmit: boolean = false;

  async order() {
    this.log.information("Ordering taxi with hotelId: " + this.hotelId);
    this.showPayInTaxiDialogTimeout && clearTimeout(this.showPayInTaxiDialogTimeout);
    await this.presentLoading();

    // I am saving the initials no matter what. Even if the validation (or request) fails,
    // I expect that the same receptionst will be present

    this.formSubmit = true;

    this.updateRoomNumberAndGuestNameRequirements();

    var roomNoControl = this.secondDestination.controls["roomNo"];
    roomNoControl.updateValueAndValidity();
    var guestNameControl = this.secondDestination.controls["guestName"];
    guestNameControl.updateValueAndValidity();
    this.paymentOption!.updateValueAndValidity();
    if (!this.secondDestination.valid) {
      await this.hideLoading();
      return;
    }

    if (!this.receptionistInitialsService.isConfigurationValid()) {
      await this.hideLoading();
      return;
    }

    if ((this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill && !this.roomNo?.value) {
      await this.hideLoading();
      return;
    }

    if (this.destination!.value != null && this.destination!.value.address == "NoDestination") {
      this.saveDirectTaxi();
      return;
    }

    var destination = this.secondDestination.value.destination;
    var from: IDestination | undefined;
    if (this.toHotelActive || destination?.address == "FromHotelAddress") {
      from = this.secondDestination.value.from;
      destination = { name: this.hotelInfo!.name, address: this.hotelInfo!.address };
    }

    //Somehow they are managing to order without jsontoken, so this is to fix that.
    if (!this.priceService.jsonWebToken) {
      this.priceService.fetchPriceInfo();
      await this.delay(3000); // 3 seconds in milliseconds
    }

    const body: ISaveOtherDestinationRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      from: from,
      location: destination,
      roomNumber: this.secondDestination.value.roomNo,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      guestName: this.secondDestination.value.guestName,
      phoneNumber: this.secondDestination.value.phoneNo,
      largeTaxi: this.secondDestination.value.largeTaxi,
      pickupTime: this.pickupTime!.value.toISOString(),
      numberOfCars: Number(this.numberOfCars?.value),
      numberOfGuests: this.secondDestination.value.largeTaxi ? this.secondDestination.value.numberOfGuests : undefined,
      stationCar: this.secondDestination.value.stationCar,
      attributesToTaxi: this.getTaxiAttributes(),
      comment: this.commentToDriver?.value,
      orderType: OrderType.Hotel,
      paymentOption: this.isManual ? PaymentOption.ApplyToBill : this.paymentOption!.value,
      rideType: this.priceService.rideType.value,
      jsonWebToken: this.priceService.jsonWebToken,
      paymentIntentId: this.paymentIntentId,
      chosenTaxiCompany: this.selectedTaxiCompany,
    }

    if ((this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill && this.roomNo?.value && (this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill) {
      this.saveApplyToBill();
      return;
    }
    else if ((this.paymentOption!.value & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      const saveOtherDestinationTerminalRequest: ISaveOtherDestinationTerminalRequest = {
        hotelId: body.hotelId,
        accesstoken: body.accessToken,
        from: body.from,
        location: body.location,
        roomNumber: body.roomNumber,
        guestName: body.guestName,
        phoneNumber: body.phoneNumber,
        pickupTime: body.pickupTime,
        rideType: RideType.FixedPrice,
        orderType: OrderType.Hotel,
        paymentOption: body.paymentOption,
        jsonWebToken: this.priceService.jsonWebToken!,
        largeTaxi: body.largeTaxi,
        numberOfGuests: body.numberOfGuests,
        numberOfCars: body.numberOfCars,
        stationCar: body.stationCar,
        attributesToTaxi: this.getTaxiAttributes(),
        chosenTaxiCompany: this.selectedTaxiCompany,
      }
      this.goToTerminal(saveOtherDestinationTerminalRequest);
      return;
    }

    this.goSaveOtherDestinations(body);
  }

  resetTimer: any;
  popUpTimer: any;

  public initTerminal(): void {
    if (this.hotelId && this.hotelInfo?.terminalLocationId && this.terminalReaderId) {
      this.paymentApiService
        .setupTerminal(this.hotelId, this.hotelInfo?.terminalLocationId, this.terminalReaderId);
    }
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  scrollToBottom() {
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 500);
  }

  showPayInTaxiDialogTimeout: NodeJS.Timeout | undefined;

  goToTerminal(body: ISaveOtherDestinationTerminalRequest) {
    const createIntentRequest: ICreatePaymentIntentRequest = {
      hotelId: this.hotelId,
      guestName: this.guestName?.value,
      terminalReaderId: this.terminalReaderId,
      jsonWebToken: body.jsonWebToken
    }

    this.log.information("goToTerminal", JSON.stringify({ body: body, createIntentRequest: createIntentRequest }));

    this.paymentApiService.createIntent(createIntentRequest).subscribe(async (data: IPaymentIntentResponse) => {

      this.log.information("createIntentResponse", JSON.stringify(data));

      if (data.message) {
        this.hideLoading();
        const errorAlert = await this.alertController.create({
          header: "No success",
          subHeader: data.message,
          cssClass: 'alertmessage',
          buttons: [
            {
              text: "Okay",
              handler: () => {

              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        this.paymentIntentId = data.paymentIntentId; //Save the payment intent id if eventually not ordering from terminal

        //Wait payment
        const collectPaymentPopupRef = this.dialog.open(CollectPaymentPopupComponent, { disableClose: true });
        await this.hideLoading();

        let payInTaxiPopupComponent: Type<TaxiPopupComponentType> = PayInTaxiPopupComponent;

        if (this.hotelInfo?.hasNewOrderingFlow) {
          payInTaxiPopupComponent = NewPayInTaxiPopupComponent;
        }

        if ((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi) {
          this.showPayInTaxiDialogTimeout = setTimeout(() => {
            const payInTaxiPopup = this.dialog.open(payInTaxiPopupComponent, {
              disableClose: true, data: {
                price: this.taxiPrice?.value,
                currency: this.hotelInfo?.currency,
              }
            });

            payInTaxiPopup.afterClosed().subscribe((result) => {
              if (result) {
                //If the user pressed Pay in Taxi, we need to cancel the payment intent and close the dialog
                this.paymentApiService.cancelCollectPaymentMethod();
                collectPaymentPopupRef.close();

                //Setting the payment option to PayInTaxi and the rideType to OtherRide and then saving the order as a normal order
                this.paymentOption!.setValue(PaymentOption.PayInTaxi);
                this.priceService.rideType.next(RideType.OtherRide);

                this.order();
              }
              else {
                //If the user pressed Continue payment at terminal, we need to close the dialog
                payInTaxiPopup.close();
              }
            });

          }, environment.receptionistPlatformPayInTaxiOpenDialogDelayMs);
        }

        await this.paymentApiService.collectPayment(data).then(async (collectPaymentResult: CollectPaymentMethodResponse) => {
          collectPaymentPopupRef.close();
          this.showPayInTaxiDialogTimeout && clearTimeout(this.showPayInTaxiDialogTimeout);

          await this.presentLoading();

          body.paymentIntentId = collectPaymentResult.paymentIntent.id;

          this.saveTaxiService.saveOtherDestinationTerminal(body).subscribe(async (data) => {
            await this.hideLoading();
            this.messageShow = true;
            this.taxiBookingId = data;
            if (body.largeTaxi) {
              this.showLargeTaxiLoading = true;
              this.timeOutTimer = 40000;
              this.startProgessTimer(30, this.taxiBookingId);
            }
            else {
              this.timeOutTimer = 0;
              this.showLargeTaxiLoading = false;
            }
            setTimeout(async () => {
              const dialogRef = this.dialog.open(PaidPopupComponent);
              dialogRef.afterClosed().subscribe(result => {
                this.resetInterface();
              });
            }, this.timeOutTimer);
          }, async (error) => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
          });
        }).catch(async (error: any) => {
          this.loading.dismiss();
        });
      }
    }, async (error) => {
      this.log.error("Error when invoking createIntent method", {
        errorObject: error,
        price: this.secondDestination.value.taxiPrice,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        terminalReaderId: this.terminalReaderId,
        paymentInfo: body
      });

      this.loading.dismiss();
      this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
    });
  }

  resetInterface(): void {
    this.paymentApiService.cancelCollectPaymentMethod();

    window.location.reload();
  }

  public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
    const dialogRef = this.dialog.open(ErrorOrderPopupComponent, {
      data: {
        taxiName,
        taxiHotelNumber
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetInterface();
      }
    });
  }

  @ViewChild("placesRef") placesRef: GooglePlaceDirective | undefined;

  options = {
    types: []
  }

  handleDestinationOrPaymentUpdated() {
    if (this.destination!.value?.address == "OrderNow") {
      this.presentLoading();
      this.saveDirectTaxi();
    }

    if (this.destination!.value?.address == "NoDestination") {
      if ((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi) {
        if ((this.paymentOption!.value & PaymentOption.PayInTaxi) != PaymentOption.PayInTaxi) {
          this.paymentOption?.setValue(PaymentOption.PayInTaxi);
        }
        this.noDestinationSelected = true;
      }

      this.hotelService.allPaymentMethodShouldDisabled.emit(true);
    }
    else {
      this.noDestinationSelected = false;
      this.hotelService.allPaymentMethodShouldDisabled.emit(false);
      if ((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi
        && (this.hotelService.paymentOptionsValue & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill
        && (this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) != PaymentOption.PayByTerminal
        && (this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill) {
        if ((this.paymentOption!.value & PaymentOption.ApplyToBill) != PaymentOption.ApplyToBill)
          this.paymentOption?.setValue(PaymentOption.PayInTaxi);
      }

      else if ((this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
        this.paymentOption?.setValue(PaymentOption.PayByTerminal);
      }
    }

    this.canOrderLargeTaxi = true;
    if (this.priceService.getPaymentOption != this.paymentOption?.value)
      this.priceService.setPaymentOption(this.paymentOption?.value, true);

    this.updateRoomNumberAndGuestNameRequirements();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  onChangePrice() {
    this.priceService.setManualPrice(this.secondDestination.value.taxiPrice);
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions === null || this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  public disableOptions(): boolean {
    return this.availableOptions?.some(x => x.toUpperCase() === 'DISABLE');
  }

  public hideEverything(): boolean {
    return (this.hotelInfo?.hotelSettings !== undefined &&
      (this.hotelInfo?.hotelSettings & HotelSettings.HideEverything) == HotelSettings.HideEverything);
  }

  public openPopUp(taxiName: string, taxiHotelNumber: string): void {
    this.dialog.open(LargeTaxiPopupComponent, {
      data: {
        taxiName: taxiName,
        taxiHotelNumber: taxiHotelNumber
      }
    });
  }

  hotelSystemIntegrationCheck() {
    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
      this.showApplyToBill = true;
    else
      this.showApplyToBill = false;

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AutoApplyToBill) === HotelSystemIntegration.AutoApplyToBill
      && !this.noDestinationSelected) {
      this.paymentOption?.setValue(PaymentOption.ApplyToBill)
    }
  };

  getTaxiAttributes(): AttributesToTaxi {
    let attributes = AttributesToTaxi.None;

    if (this.animal?.value === true) {
      attributes |= AttributesToTaxi.Animal;
    }
    if (this.bike?.value === true) {
      attributes |= AttributesToTaxi.Bike;
    }
    if (this.electricCar?.value === true) {
      attributes |= AttributesToTaxi.ElectricCar;
    }
    if (this.carseat?.value === true) {
      attributes |= AttributesToTaxi.Carseat;
    }
    if (this.oneBoosterSeat?.value === true) {
      attributes |= AttributesToTaxi.OneBoosterSeat;
    }
    if (this.twoBoosterSeats?.value === true) {
      attributes |= AttributesToTaxi.TwoBoosterSeats;
    }
    if (this.childSeat?.value === true) {
      attributes |= AttributesToTaxi.ChildSeat;
    }
    if (this.stationCar?.value === true) {
      attributes |= AttributesToTaxi.EstateCar;
    }
    if (this.hybridCar?.value === true) {
      attributes |= AttributesToTaxi.HybridCar;
    }

    return attributes;
  }

  public roomNumberGetInfo() {
    if (this.roomNo?.value) {
      this.getHotelSystem.getCustomerInfo(this.roomNo?.value).subscribe((data: HotelSystemCustomer) => {
        if (data && (data.phoneNumber || data.guestName))
          this.hotelSystemIntegrationCheck();
        else
          this.showApplyToBill = false;

        if (data && data.phoneNumber) {
          this.phoneNumber = { phoneNumber: data.phoneNumber, countryCode: data.countryCodeInt };
          let button = document.getElementById("roomNumberButton")
          button!.style.backgroundColor = '#46adae'
        }

        if (data && data.guestName) {
          this.guestName?.setValue(data.guestName)
          let button = document.getElementById("roomNumberButton")
          button!.style.backgroundColor = '#46adae'
        }

        if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
          this.hideNameAndPhone = false;

        if (data == null)
          this.dialog.open(RoomNumberIncorrect);
      }, async (error) => {
        if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
          this.hideNameAndPhone = false;

        await this.hideLoading();
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
      });
    }
  }

  public onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }

  public saveApplyToBill(): void {
    var from: IDestination | undefined;
    var destination = this.secondDestination.value.destination;
    if (this.toHotelActive || destination?.address == "FromHotelAddress") {
      from = this.secondDestination.value.from;
      destination = { name: this.hotelInfo!.name, address: this.hotelInfo!.address };
    }

    let body: ISaveOtherDestinationTerminalRequest = {
      hotelId: this.hotelId,
      accesstoken: this.accessToken,
      from: from,
      location: destination,
      roomNumber: this.secondDestination.value.roomNo,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      guestName: this.secondDestination.value.guestName,
      phoneNumber: this.secondDestination.value.phoneNo,
      largeTaxi: this.secondDestination.value.largeTaxi,
      pickupTime: this.pickupTime!.value.toISOString(),
      numberOfCars: Number(this.numberOfCars?.value),
      numberOfGuests: this.secondDestination.value.largeTaxi ? this.secondDestination.value.numberOfGuests : undefined,
      stationCar: this.secondDestination.value.stationCar,
      comment: this.commentToDriver?.value,
      orderType: OrderType.Hotel,
      paymentOption: PaymentOption.ApplyToBill,
      rideType: RideType.FixedPrice,
      jsonWebToken: this.priceService.jsonWebToken!,
      paymentIntentId: this.paymentIntentId,
      attributesToTaxi: this.getTaxiAttributes(),
      chosenTaxiCompany: this.selectedTaxiCompany,
    }
    this.saveTaxiService.saveOtherDestinationTerminal(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    })
  }

  async saveDirectTaxi() {
    const body: IDirectTaxiRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      roomNumber: this.secondDestination.value.roomNo,
      guestName: this.secondDestination.value.guestName,
      numberOfCars: this.numberOfCars?.value,
      numberOfGuests: this.numberOfGuests?.value,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      phoneNumber: this.secondDestination.value.phoneNo,
      pickupTime: this.pickupTime!.value.toISOString(),
      stationCar: this.secondDestination.value.stationCar,
      comment: this.commentToDriver?.value,
      paymentOption: PaymentOption.PayInTaxi,
      largeTaxi: this.secondDestination.value.largeTaxi,
      orderType: OrderType.Hotel,
      attributesToTaxi: this.getTaxiAttributes(),
      chosenTaxiCompany: this.selectedTaxiCompany,
    }
    this.saveTaxiService.directTaxiRide(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  goSaveOtherDestinations(body: ISaveOtherDestinationRequest) {
    this.saveTaxiService.saveOtherDestinations(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.receiptService.update();
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    })
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async startProgessTimer(seconds: number, Id: string) {
    const timer = interval(1000);

    const sub = timer.subscribe((sec) => {
      this.progressValue = (sec / seconds) * 100;

      if (sec === seconds - 5) {
        this.rideService.getLargeTaxiStatus(Id).subscribe(data => {
          this.largeTaxiCarResponse = this.translate.instant(`largeTaxiFound`).replace('{largeTaxiCarNo}', data.bookingId);
        }, (error => {
          this.showEmote = false;
          this.largeTaxiCarResponse = this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
        }))
      }
      if (sec === seconds) {
        sub.unsubscribe();
        this.progressValue = 0;
        this.loadingDone = true;
      }
    });
  }
}

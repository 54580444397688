import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
@Injectable({
    providedIn: 'root'
})
export class ValidationTools {
    oneOfTwoFieldsRequired(depControl: AbstractControl) {
        return (primControl: AbstractControl): ValidationErrors | null => {
            var primControlValue = primControl.value;
            var secControlValue = depControl.value;
            if (primControlValue && primControlValue != "") {
                return null;
            }
            else if (secControlValue && secControlValue != "") {
                return null;
            } else {
                return { "roomNoOrGuestNameRequired": true }
            }
        }
    }
}
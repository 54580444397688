import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from 'src/app/services/save-taxi.service';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { AirportDirection, PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { HotelSystemCustomer } from 'src/app/models/hotel-system-intergration/hotel-system-customer.interface';
import { MatDialog } from '@angular/material/dialog';
import { GetHotelSystem } from 'src/app/services/get-hotel.service';
import { RoomNumberIncorrect } from 'src/app/shared/components/room-number-incorrect-popup/room-number-incorrect-popup.component';
import { interval } from 'rxjs';
import { AttributesToTaxi, RideService } from 'src/app/services/ride.service';
import { ValidationTools } from 'src/tools/ValidationTools';

@Component({
  selector: 'app-from-airport',
  templateUrl: './from-airport.component.html',
  styleUrls: ['./from-airport.component.scss']
})
export class FromAirportComponent implements OnInit, OnDestroy {

  availableOptions: string[] = [];

  showOnCheck = '';
  isShow($event: any) {
    this.showOnCheck = $event.value;
  }

  @Input() isManual = false;
  showOnLarge: boolean = false;
  isShowShare($event: any) {
    this.showOnLarge = this.fromAirport.value.largeTaxi;
    if (this.fromAirport.value.largeTaxi || this.fromAirport.value.stationCar)
      this.shareTaxi?.disable();
    else
      this.shareTaxi?.enable();

    this.priceService.setIsLargeTaxi(this.fromAirport.value.largeTaxi);
    if (this.fromAirport.value.largeTaxi) {
      this.stationCar?.disable();
    }
    else if (this.fromAirport.value.stationCar) {
      this.largeTaxi?.disable();
    }
    if (!this.fromAirport.value.largeTaxi) {
      this.stationCar?.enable();
    }
    if (!this.fromAirport.value.stationCar) {
      this.largeTaxi?.enable();
    }
  }

  public fromAirport: FormGroup;
  hotelId: any;
  airportId: any;
  accessToken: any;
  selectedAirportId?: string;
  loading!: HTMLIonLoadingElement;
  messageShow: boolean = false;
  sharedRideSavings?: number;
  largeTaxiPrice?: number;
  taxiCanShareTaxi: boolean;
  taxiCanShareTaxiSubscription: Subscription;
  hotelSharesTaxi: boolean;
  hotelSharesTaxiSubscription: Subscription;
  pickupTimeSetManually = false;
  currency = '';
  payInTaxiAiportAllowed: boolean | undefined = false;
  getCustomerAllowed: boolean = false;
  applyToBillAllowed: boolean = false;
  showApplyToBill: boolean = false;;
  progressValue: number = 0;
  timeOutTimer: number = 0;
  largeTaxiCarResponse: string = "";
  loadingDone: boolean = false;
  showLargeTaxiLoading: boolean = false;
  taxiBookingId: string = "";
  defaultCommentToTaxiDriverSubscription?: Subscription;
  currencySubscription?: Subscription;
  numberOfCarsSelectionOptions: number[] = [1, 2, 3, 4];
  isLogoAvailable: { [key: string]: boolean } = {};
  taxiNames: string[] = [];
  selectedTaxiCompany: string | undefined;

  constructor(private fb: FormBuilder, private alertController: AlertController, public translate: TranslateService, private getTaxiService: GetTaxiService, private parameterService: ParameterService, private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, public receptionistService: ReceptionistInitialsService, private hotelService: HotelService, public dialog: MatDialog, private getHotelSystem: GetHotelSystem, private rideService: RideService, private tools: ValidationTools, private cdr: ChangeDetectorRef) {
    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;

      let body = {
        hotelId: this.hotelId,
        airportId: this.airportId,
        accessToken: this.accessToken
      }

      this.getTaxiService.getTaxiToHotel(body).subscribe(data => {
        this.getCustomerAllowed = data.getCustomerAllowed;
        this.applyToBillAllowed = data.applyToBillAllowed;
        this.sharedRideSavings = data.sharedRideSavings;

        this.largeTaxiPrice = data.largeTaxiPrice;
        this.payInTaxiAiportAllowed = data.payInTaxiAiportAllowed;

        if (!this.payInTaxiAiportAllowed)
          this.paymentOption?.setValue(PaymentOption.ApplyToBill)

      }, async (error) => { });

      priceService.setAirportDirection(AirportDirection.fromAirport);
      priceService.setIsLargeTaxi(false);
    });

    this.fromAirport = fb.group({
      flightNo: [null, Validators.required],
      guestName: [null, Validators.required],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]],
      largeTaxi: [false, Validators.required],
      stationCar: [false],
      animal: [false],
      bike: [false],
      electricCar: [false],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      numberOfGuests: [4],
      shareTaxi: [null, Validators.required],
      luggage: [],
      commentToDriver: [null],
      pickupTime: [new Date(), Validators.required],
      paymentOption: [PaymentOption.PayInTaxi, Validators.required],
      taxiPrice: [0],
      roomNo: [],
      email: [null, [Validators.email]]
    });

    this.phoneNo!.setValidators([this.tools.oneOfTwoFieldsRequired(this.email!)]);
    this.email!.setValidators([this.tools.oneOfTwoFieldsRequired(this.phoneNo!)])

    // Revalidate the dependent control when the other control changes
    this.phoneNo!.valueChanges.subscribe(() => {
      this.email!.updateValueAndValidity();
    });

    this.email!.valueChanges.subscribe(() => {
      this.phoneNo!.updateValueAndValidity();
    });

    this.taxiCanShareTaxi = taxiConfigurationService.canShareTaxiValue;
    if (!this.taxiCanShareTaxi)
      this.shareTaxi!.setValue(false);

    this.taxiCanShareTaxiSubscription = taxiConfigurationService.canShareTaxi.subscribe(v => {
      this.taxiCanShareTaxi = v;
      if (!this.taxiCanShareTaxi)
        this.shareTaxi!.setValue(false);
    });

    this.hotelSharesTaxi = hotelService.sharingTaxiesValue;
    if (!this.hotelSharesTaxi)
      this.shareTaxi!.setValue(false);

    this.hotelSharesTaxiSubscription = hotelService.sharingTaxies.subscribe(v => {
      this.hotelSharesTaxi = v;
      if (!this.hotelSharesTaxi)
        this.shareTaxi!.setValue(false);
    });

    this.commentToDriver!.setValue(this.translate.instant("airportComment"));
    this.defaultCommentToTaxiDriverSubscription = hotelService.defaultCommentToTaxiDriver.subscribe(comment => this.commentToDriver!.setValue(comment));
    this.currencySubscription = hotelService.currency.subscribe(currency => this.currency = currency);
  }

  updateValidators(): void {
    this.roomNo!.updateValueAndValidity();
    this.guestName!.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.taxiConfigurationService.taxiAvailableOptions.subscribe((data) => {
      this.availableOptions = data;
    });
    this.priceService.taxiPrice.subscribe(p => {
      this.taxiPrice?.setValue(p?.customerPrice);
    });
    this.priceService.setAirportDirection(AirportDirection.fromAirport);

    this.fromAirport.get("largeTaxi")?.valueChanges.subscribe((value: boolean) => {
      this.priceService.setIsLargeTaxi(value);
    });

    this.taxiConfigurationService.taxiNames.subscribe((data) => {
      this.taxiNames = data;
      this.taxiNames.forEach(taxiName => {
        this.isLogoAvailable[taxiName] = true;
      });
    });

    this.taxiConfigurationService.taxiName.subscribe((data) => {
      if (!this.selectedTaxiCompany) {
        this.selectedTaxiCompany = data;
        this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.taxiCanShareTaxiSubscription)
      this.taxiCanShareTaxiSubscription.unsubscribe();

    if (this.hotelSharesTaxiSubscription)
      this.hotelSharesTaxiSubscription.unsubscribe();

    if (this.defaultCommentToTaxiDriverSubscription)
      this.defaultCommentToTaxiDriverSubscription.unsubscribe();

    if (this.currencySubscription)
      this.currencySubscription.unsubscribe();
  }

  applyToBill() {
    var payByBill = (this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill;

    if (!payByBill) {
      this.shareTaxi?.setValue(false);
      this.isShow({ value: false });
    }

    return payByBill;
  }

  paymentOptionFilter(option: PaymentOption) {
    if ((option & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      return option - PaymentOption.PayByTerminal;
    }
    return option;
  };

  hotelPaymentChanged(hotelPayment: boolean) {
    this.shareTaxi!.setValue(false);

    if (hotelPayment)
      this.paymentOption!.setValue(PaymentOption.HotelPays);
    else
      this.paymentOption!.setValue(PaymentOption.PayInTaxi);
  }

  hasMultipleTaxiCompanies(): boolean {
    if (this.taxiNames && this.taxiNames.length > 1)
      return true;
    else
      return false;
  }

  get animal() {
    return this.fromAirport.get('animal');
  }
  get bike() {
    return this.fromAirport.get('bike');
  }
  get electricCar() {
    return this.fromAirport.get('electricCar');
  }
  get commentToDriver() {
    return this.fromAirport.get('commentToDriver');
  }
  get roomNo() {
    return this.fromAirport.get('roomNo');
  }
  get flightNo() {
    return this.fromAirport.get('flightNo');
  }
  get phoneNo() {
    return this.fromAirport.get('phoneNo');
  }
  get largeTaxi() {
    return this.fromAirport.get('largeTaxi');
  }
  get shareTaxi() {
    return this.fromAirport.get('shareTaxi');
  }
  get pickupTime() {
    return this.fromAirport.get('pickupTime');
  }
  get guestName() {
    return this.fromAirport.get('guestName');
  }
  get numberOfGuests() {
    return this.fromAirport.get('numberOfGuests');
  }
  get stationCar() {
    return this.fromAirport.get('stationCar');
  }
  get email() {
    return this.fromAirport.get('email');
  }
  get numberOfCars() {
    return this.fromAirport.get('numberOfCars');
  }
  get paymentOption() {
    return this.fromAirport.get('paymentOption');
  }
  get taxiPrice() {
    return this.fromAirport.get('taxiPrice');
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  onTaxiBoxClick(name: string): void {
    this.selectedTaxiCompany = name;
    this.taxiConfigurationService.updateSelectedTaxiCompany(name);
    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;
    this.priceService.setChosenTaxiCompany(name, true);
  }

  formSubmit: boolean = false;
  async order() {
    await this.presentLoading()
    this.paymentOption!.updateValueAndValidity();
    this.updateValidators();

    if (!this.receptionistService.isConfigurationValid()) {
      await this.hideLoading();
      return;
    }

    this.formSubmit = true;
    if (!this.fromAirport.valid) {
      await this.hideLoading()
      return;
    }

    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      airportId: this.selectedAirportId,
      phoneNumber: this.fromAirport.value.phoneNo,
      flightNumber: this.fromAirport.value.flightNo,
      shareRide: this.fromAirport.value.shareTaxi,
      comment: this.commentToDriver?.value,
      pickupTimeSetManually: this.pickupTimeSetManually,
      numberOfCars: Number(this.numberOfCars?.value),
      largeTaxi: this.fromAirport.value.largeTaxi,
      guestName: this.fromAirport.value.guestName,
      receptionistInitials: this.receptionistService.getInitials(),
      // We only show the numberOfGuests option to the user for large taxies
      numberOfGuests: this.fromAirport.value.largeTaxi ? this.fromAirport.value.numberOfGuests : undefined,
      flightNotFound: undefined,
      stationCar: this.fromAirport.value.stationCar,
      attributesToTaxi: this.getTaxiAttributes(),
      email: this.fromAirport.value.email,
      paymentOption: this.isManual ? PaymentOption.ApplyToBill : this.paymentOption!.value,
      flightDate: this.pickupTime!.value,
      roomNumber: this.roomNo?.value,
      chosenTaxiCompany: this.selectedTaxiCompany,
    }

    this.saveTaxiService.saveTaxiToHotel(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.largeTaxi) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        window.location.reload();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      console.log(error);
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode.replace('ForGuest', '')}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  flightNoKeyPress(event: any) {
    // No spaces (character code 32) allowed
    if (event.keyCode === 32)
      event.preventDefault();
  }

  fakeFlightData() {
    this.flightNo!.setValue('F4KE FL1GHT');
    this.pickupTime!.setValue(this.addDays(new Date(), 2));
    this.pickupTimeSetManually = false;
    this.pickupTime!.setValidators([]);
  }

  pickupTimeChange() {
    this.pickupTimeSetManually = true;
  }

  getTaxiAttributes(): AttributesToTaxi {
    let attributes = AttributesToTaxi.None;

    if (this.animal?.value === true) {
      attributes |= AttributesToTaxi.Animal;
    }
    if (this.bike?.value === true) {
      attributes |= AttributesToTaxi.Bike;
    }
    if (this.electricCar?.value === true) {
      attributes |= AttributesToTaxi.ElectricCar;
    }

    return attributes;
  }

  airportSelected(airportId: string) {
    this.selectedAirportId = airportId;

    this.priceService.setAirport(airportId);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onChangePrice() {
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions === null || this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  public disableOptions(): boolean {
    return this.availableOptions?.some(x => x.toUpperCase() === 'DISABLE');
  }

  onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }

  public roomNumberGetInfo() {
    if (this.roomNo?.value) {
      this.getHotelSystem.getCustomerInfo(this.roomNo?.value).subscribe((data: HotelSystemCustomer) => {
        if (data && (data.phoneNumber || data.guestName)) {
          if (this.applyToBillAllowed) {
            this.showApplyToBill = true;
            this.paymentOption?.setValue(PaymentOption.ApplyToBill);
          }
        }

        if (data && data.phoneNumber) {
          this.phoneNo?.setValue(data.phoneNumber);
          let button = document.getElementById("roomNumberButton")
          button!.style.backgroundColor = '#46adae'
        }

        if (data && data.guestName) {
          this.guestName?.setValue(data.guestName)
          let button = document.getElementById("roomNumberButton")
          button!.style.backgroundColor = '#46adae'
        }

        if (data == null)
          this.dialog.open(RoomNumberIncorrect);

      }, async (error) => {
        await this.hideLoading();
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
      });
    }
  }

  async startProgessTimer(seconds: number, Id: string) {
    const timer = interval(1000);

    const sub = timer.subscribe((sec) => {
      this.progressValue = (sec / seconds) * 100;

      if (sec === seconds - 5) {
        this.rideService.getLargeTaxiStatus(Id).subscribe(data => {
          this.largeTaxiCarResponse = this.translate.instant(`largeTaxiFound`).replace('{largeTaxiCarNo}', data);
        }, (error => {
          this.largeTaxiCarResponse = this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
        }))
      }
      if (sec === seconds) {
        sub.unsubscribe();
        this.progressValue = 0;
        this.loadingDone = true;
      }
    });
  }
}
